import React from 'react';
import Table from './Table';

function App() {
  return (
    <div className="container">
      <div className="main">
        <div className="article">
          <Table />
        </div>
      </div>
    </div>
  );
}

export default App;
