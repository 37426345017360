import { useState, useRef, useEffect } from 'react';
import parse from 'html-react-parser';
import { initTable } from './utils';

const extractTableData = (parsedTable) => {
  // Ensure parsedTable is an array
  const elements = Array.isArray(parsedTable) ? parsedTable : [parsedTable];

  // Find the table element
  const tableElement = elements.find(element => element.type === 'table');

  // Ensure tableElement is valid
  if (!tableElement) return [];

  // Check for tbody and extract rows from tbody or directly from the table
  let rows;
  if (tableElement.props.children.type === 'tbody') {
    rows = tableElement.props.children.props.children;
  } else {
    rows = tableElement.props.children;
  }

  // Ensure rows is an array (in case of a single row)
  if (!Array.isArray(rows)) {
    rows = [rows];
  }

  // Filter out only tr elements and extract cells
  const data = rows.filter(row => row.type === 'tr').map(row => {
    let cells = row.props.children;

    // Ensure cells is an array (in case of a single cell)
    if (!Array.isArray(cells)) {
      cells = [cells];
    }

    return cells.filter(cell => cell.type === 'td' || cell.type === 'th').map(cell => cell.props.children);
  });

  return data;
};


const Table = () => {
  const [tableHtml, setTableHTML] = useState(initTable());
  const parsedTable = parse(tableHtml);
  const initialData = extractTableData(parsedTable);
  const [tableData, setTableData] = useState(initialData);
  const [saveData, setSaveData] = useState(initialData);
  const [widths, setWidths] = useState([200, 200, 300, 300]);
  const [edit, setEdit] = useState(false);
  const tableRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(null);

  const { className } = parsedTable.props;

  const addColumn = (index) => {
    const newData = tableData.map(row => {
      const newRow = [...row];
      newRow.splice(index + 1, 0, '');
      return newRow;
    });
    setTableData(newData);
    setSaveData(newData);

    const newWidths = [...widths];
    newWidths.splice(index + 1, 0, 200);
    console.log(newWidths)
    setWidths(newWidths);
  };

  const addRow = (index) => {
    const newRow = new Array(tableData[0].length).fill('');
    const newData = [...tableData];
    newData.splice(index + 1, 0, newRow);
    setTableData(newData);
    setSaveData(newData);
  };

  const removeColumn = (index) => {
    const newData = tableData.map(row => {
        const newRow = [...row];
        newRow.splice(index, 1);
        return newRow;
    });
    setTableData(newData);
    setSaveData(newData);

    const newWidths = [...widths];
    newWidths.splice(index, 1);
    setWidths(newWidths);
  };

  const removeRow = (index) => {
    const newData = [...tableData];
    newData.splice(index, 1);
    setTableData(newData);
    setSaveData(newData);
  };

  const handleSetWidths = (value, index) => {
    const newWidths = [...widths];
    newWidths[index] = value;
    setWidths(newWidths);
  }

  const handleSetContent = (value, rowIndex, cellIndex) => {
    const newData = [...saveData];
    if (newData[rowIndex]) {
      const newRow = [...newData[rowIndex]];
      newRow[cellIndex] = typeof value === 'string' ? parse(value) : value;
      newData[rowIndex] = newRow;
    }

    setSaveData(newData);
  }

  const handleSave = (value) => {
    if (!value) {
      setTableData(saveData);
    }

    setEdit(value);
  };

  const updateCursorPosition = (element) => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        console.log(range)
        setCursorPosition(range);
    }
};

  const handleKeyInput = (value, rowIndex, cellIndex) => {
    console.log(value)
    if (value.includes('[]')) {
      if (confirm(`At this point an autocomplete field will appear to you so you can select the UP shortcode from the dropdown. Since this is a POC, we are going to simulate the selection of a up shortcode. Please click on "OK" to proceed.`)) {
        if (cursorPosition) {
          // Create a new range from the stored cursor position
          const range = cursorPosition.cloneRange();
  
          // Delete the contents of the current range (this is needed if you want to replace the current selection)
          range.deleteContents();
  
          // Create a new text node with the selected option
          const textNode = document.createTextNode('up id=192 name="chase-sapphire-preferred-annual-fee"');
  
          // Insert the text node at the range
          range.insertNode(textNode);
  
          // Move the cursor after the inserted text
          range.setStartAfter(textNode);
          range.collapse(true);
  
          // Update the selection with the new range
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }
  };

  return (
    <>
      <h2>Editing Tables POC</h2>
      <p>This is a demo for editing tables based on the contentEditable property in HTML. Here are some instructions for the playground.</p>
      <ul>
        <li>You can click on "Edit" or "Save" buttons to simulate the edition and saving of a table.</li>
        <li>On each "Save", the table will push the current HTML to the edge so it can be served by ESI.</li>
        <li>The shortcodes contents will be switched at the edge. There will be a preview button where the table can be previewed with the shortcodes filled.</li>
        <li>The current interface has only a few controls to show case what is possible with HTML and contentEditable.</li>
        <li><strong>To test the autocomplete of a shortcode just type [] anywhere in the editable content of the table.</strong></li>
        <li>If you'd like to reset the application just refresh the page. It will return to defaults.</li>
      </ul>
      <div className="buttons">
        <button onClick={() => handleSave(!edit)}>
          {edit ? 'Save' : 'Edit'}
        </button>
      </div>
      {edit && widths.map((width, index) =>
        <input
          key={index}
          type="number"
          defaultValue={width}
          step="10"
          onChange={(e) => handleSetWidths(e.target.value, index)}
          style={{width: `${width}px`, minWidth: '175px'}}
        />
      )}
      <table ref={tableRef} className={className}>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  style={{
                    width: `${widths[cellIndex]}px`,
                    maxWidth: `${widths[cellIndex]}px`,
                    minWidth: `175px`
                  }}
                >
                  {edit &&
                    <>
                      <div
                        contentEditable={true}
                        onBlur={(e) => handleSetContent(e.currentTarget.innerHTML, rowIndex, cellIndex)}
                        onInput={(e) => handleKeyInput(e.currentTarget.innerHTML, rowIndex, cellIndex)}
                        onKeyUp={() => updateCursorPosition()}
                        onClick={() => updateCursorPosition()}
                      >
                        {cell}
                      </div>
                      {rowIndex === 0 ?
                        <div title="Add Column" className="add-column-icon">
                          <button onClick={() => removeColumn(cellIndex)}>-</button>
                          <button onClick={() => addColumn(cellIndex)}>+</button>
                        </div>
                        :
                        <div title="Add Row" className="add-row-icon">
                          <button onClick={() => removeRow(rowIndex)}>-</button>
                          <button onClick={() => addRow(rowIndex)}>+</button>
                        </div>
                      }
                    </>
                  }
                  {!edit && cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default Table;