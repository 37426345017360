import ReactDomServer from 'react-dom/server';

export const initTable = () => {
  return ReactDomServer.renderToString(
    <table className="comparison-table scrollable" contentEditable="true">
      <tr>
        <td><strong>Card Name</strong></td>
        <td><strong>Best For</strong></td>
        <td><strong>Welcome Bonus and Annual Fee</strong></td>
        <td><strong>Benefits</strong></td>
      </tr>
      <tr>
        <td><strong><a href="/credit-cards/reviews/chase-sapphire-preferred-card/">Chase Sapphire Preferred<sup>®</sup> Card</a></strong></td>
        <td>Beginners</td>
        <td>
          <ul>
            <li><strong>Earn 60,000 points after spending $4,000 on purchases in the first 3 months.</strong></li>
            <li>Annual fee of $95</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>5x points on travel purchased through Chase Ultimate Rewards</li>
            <li>3x points on dining, online grocery purchases, and select streaming services</li>
            <li> 2x points on all other travel purchases</li>
            <li>$50 annual Ultimate Rewards hotel credit</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td><strong><a href="/credit-cards/reviews/capital-one-venture-rewards-credit-card/">Capital One Venture Rewards Credit Card</a></strong></td>
        <td>Beginners</td>
        <td>
          <ul>
            <li><strong>Earn 75,000 miles when you spend $4,000 on purchases in the first 3 months.</strong></li>
            <li>Annual fee of $95 (<a href="https://upgradedpoints.com/go/CapOneVentureTCCRF/TBL/dissn.1696519593285.1136432536.30/" className="" rel="sponsored nofollow" target="_blank">rates &amp; fees</a>)</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>5x miles on hotels and rental cars booked through Capital One Travel</li>
            <li>Unlimited 2x miles on every purchase, every day</li>
            <li>Global Entry or TSA PreCheck credit</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td><strong><a href="/credit-cards/reviews/american-express-platinum-card/">The Platinum Card<sup>®</sup> from American Express</a></strong></td>
        <td>Premium rewards</td>
        <td>
          <ul>
            <li><strong>Earn 80,000 points after spending $8,000 on purchases in the first 6 months.</strong></li>
            <li>Annual fee of $695</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>5x points for flights booked directly with airlines or with Amex Travel</li>
            <li>5x points on prepaid hotels booked with Amex Travel</li>
            <li><strong>Access to over 1,400 worldwide lounges</strong>, including Amex Centurion Lounges, Priority Pass (upon enrollment), and more</li>
            <li><strong>35% airline bonus</strong> when using Pay With Points (up to 1,000,000 bonus points per calendar year)</li>
            <li>A generous collection of statement credits</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td><strong><a href="/credit-cards/reviews/american-express-gold-card/">American Express<sup>®</sup> Gold Card</a></strong></td>
        <td>Everyday spending</td>
        <td>
          <ul>
            <li><strong>Earn 60,000 points when you spend $6,000 on purchases in the first 6 months.</strong></li>
            <li>Annual fee of $250</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>4x points at U.S. restaurants</li>
            <li>4x points at U.S. supermarkets (on up to $25,000 per calendar year in purchases, then 1x)</li>
            <li>3x points on flights booked directly with airlines or on Amex Travel</li>
            <li>Up to $120 in annual dining credits</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td><strong><a href="/credit-cards/reviews/chase-sapphire-reserve/">Chase Sapphire Reserve<sup>®</sup></a></strong></td>
        <td>Premium rewards</td><td>
          <ul>
            <li><strong>Earn 60,000 bonus points after you spend $4,000 on purchases in the first 3 months.</strong></li>
            <li>Annual fee of $550</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>10x total points on hotels and car rentals and 5x points on air travel purchased via Chase Ultimate Rewards</li>
            <li>3x points on other travel and dining </li>
            <li>Up to $300 annual travel credit&nbsp;</li>
            <li>Priority Pass Select membership</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td><strong><a href="/credit-cards/reviews/chase-ink-business-preferred-credit-card/">Ink Business Preferred<sup>®</sup> Credit Card</a></strong></td>
        <td>Business owners that travel</td>
        <td>
          <ul>
            <li><strong><strong><u><i>NEW OFFER:</i></u> Earn 100,000 bonus points</strong> after you spend $8,000 on purchases in the first 3 months.</strong></li>
            <li>Annual fee of $95</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>3x points on the first $150,000 spent in combined purchases on&nbsp;travel, shipping purchases, internet, cable and phone services, advertising purchases made with social media sites and search engines&nbsp;each account anniversary year</li>
            <li>Cell phone protection</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td><strong><a href="/credit-cards/reviews/amex-business-platinum/">The Business Platinum Card<sup>®</sup> from American Express</a></strong></td>
        <td>Business owners that travel</td><td>
          <ul>
            <li><strong><strong><i><u>OUR BEST OFFER:</u></i></strong><strong> Earn 120,000 points</strong> after you spend $15,000 on purchases in the first 3 months.</strong></li>
            <li>Annual fee of $695</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>5x points for flights booked directly with airlines or with Amex Travel</li>
            <li>5x points on prepaid hotels booked with Amex Travel</li>
            <li>1.5x points on eligible purchases at U.S. construction material and hardware suppliers, electronic goods retailers and software and cloud system providers, and shipping providers.</li>
            <li>1.5x points on purchases of $5,000 or more everywhere else, on up to $2 million of these purchases per calendar year</li>
            <li><strong>Access to over 1,400 worldwide lounges</strong>, including Amex Centurion Lounges, Priority Pass (upon enrollment), and more</li>
            <li><strong>35% airline bonus</strong> when using Pay With Points (up to 1,000,000 bonus points per calendar year)</li>
            <li>A generous collection of statement credits</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td><strong><a href="/credit-cards/reviews/american-express-business-gold-card/">American Express<sup>®</sup> Business Gold Card</a></strong></td>
        <td>Small business owners</td><td>
          <ul>
            <li><strong><u><i><strong>CARD REFRESH:</strong></i></u><strong><strong> Earn 70,000 points after you spend $10,000 on purchases in the first 3 months.</strong></strong></strong></li>
            <li>Annual fee of $295 ($375 if application is received on or after 2/1/24)</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>4x points on the&nbsp;2 select categories where your business spent the most each month (up to the first $150,000 in combined purchases from these 2 categories each calendar year)</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td><strong><a href="/credit-cards/reviews/chase-ink-cash-business-card/">Ink Business Cash<sup>®</sup> Credit Card</a></strong></td>
        <td>Small business owners</td><td>
          <ul>
            <li><strong><u><i><strong>NEW OFFER:</strong></i></u> Earn $900 cash-back after spending $6,000 on purchases in the first 3 months.</strong></li>
            <li>Annual fee of $0</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>5% cash back&nbsp;on the first $25,000 spent in combined purchases at&nbsp;office supply stores and on internet, cable and phone services&nbsp;each account anniversary year</li>
            <li>2% cash back&nbsp;on the first $25,000 spent in combined purchases&nbsp;at gas stations and restaurants each account anniversary year</li>
          </ul>
        </td>
      </tr>
    </table>
  );
}